/* eslint-disable no-nested-ternary */
import { API } from 'aws-amplify';
import moment from 'moment';
import { find, take, orderBy } from 'lodash';
import { CompareArrowsOutlined } from '@material-ui/icons';
import { API_NAMES } from '../../awsconfig';
import fieldChanged, { liteFieldChanged } from './FieldChanged';
import apiUris from '../../apiUris';
import { setCompleteTab } from './tab';
import { noErrors, validationErrors, handleHTTPError } from './errorHandler';
import { showAskPreviousLoan, previousForLiability, showAskPartnerPropDetails } from '../lib/utils';
import { setCognitoId } from './authHandler';
import * as log from '../util/log';
import {
  APPLICANT_TYPE,
  PRIMARY_APPLICANT,
  CO_APPLICANT,
  SPOUSE_APPLICANT,
  EMPTYPE_SELF,
  EMPSTATUS_RETIRED,
  EMPSTATUS_UNEMPLOYED,
  COMMERCIAL_EQUIPMENT,
  BUSINESS,
  RESET_ALL_DATA_APPLICANTS,
  RESET_ALL_DATA_ESTIMATION,
  assetMappingSF,
} from '../util/constants';
import { setApplicationStrucutureStepper } from './stepperDetails';

export const POPULATE_CARMAKE = 'POPULATE_CARMAKE';
export const POPULATE_CARMODEL = 'POPULATE_CARMODEL';
export const PREPOPULATE_DATA_CAR = 'PREPOPULATE_DATA_CAR';
export const POPULATE_ESTIMATION = 'POPULATE_ESTIMATION';
export const UPDATE_ESTIMATION = 'UPDATE_ESTIMATION';
export const POPULATE_REGOVEHICLEDETAILS = 'POPULATE_REGOVEHICLEDETAILS_CAR';
export const CLEAR_REGOVEHICLEDETAILS = 'CLEAR_REGOVEHICLEDETAILS_CAR';
export const CLEAR_REGOVEHICLEDETAILS_SETTLEMENT = 'CLEAR_REGOVEHICLEDETAILS_SETTLEMENT';
export const POPULATE_CAR_DETAILS = 'POPULATE_CAR_DETAILS_CAR';
export const POPULATE_OPP_DETAILS = 'POPULATE_OPP_DETAILS_APPLICANTS';
export const POPULATE_POSTLENDERSELECT_DETAILS = 'POPULATE_POSTLENDERSELECT_APPLICANTS';
export const POPULATE_LOAN_DETAILS = 'POPULATE_LOAN_DETAILS_ESTIMATION';
export const REPOPULATE_LOAN_DETAILS = 'REPOPULATE_LOAN_DETAILS_ESTIMATION';
export const POPULATE_APPLICANTS = 'POPULATE_APPLICANTS';
export const POPULATE_FINANCES = 'POPULATE_FINANCES_APPLICANTS';
export const POPULATE_YEARS = 'POPULATE_YEARS_CAR';
export const POPULATE_YEARS_SETTLEMENT = 'POPULATE_YEARS_CAR_SETTLEMENT';
export const POPULATE_CARMAKES = 'POPULATE_CARMAKES_CAR';
export const POPULATE_CARMODELS = 'POPULATE_CARMODELS_CAR';
export const POPULATE_CARVARIANTS = 'POPULATE_CARVARIANTS_CAR';
export const POPULATE_CARSERIES = 'POPULATE_CARSERIES_CAR';
export const POPULATE_FACTORYOPTIONS = 'POPULATE_FACTORYOPTIONS_CAR';
export const RETRIVE_ADDRRESS_APPLICANTS = 'RETRIVE_ADDRRESS_APPLICANTS';
export const CLEAR_ADDRRESS_APPLICANTS = 'CLEAR_ADDRRESS_APPLICANTS';
export const REFERRAL_DATA = 'REFERRAL_DATA_CAR';
export const POPULATE_CONTACT_DETAILS = 'POPULATE_CONTACT_DETAILS_APPLICANTS';
export const POPULATE_ABN_SAVED_DATA = 'POPULATE_ABN_SAVED_DATA_APPLICANTS';
export const CAR_PURPOSE_CHANGED = 'CAR_PURPOSE_CHANGED_CAR';
export const POPULATE_QUOTE_DETAILS = 'POPULATE_QUOTE_DETAILS_ESTIMATION';
export const CLEAR_LEAD_VALUES = 'CLEAR_LEAD_VALUES_CAR';
export const purposeUseFieldChanged = () => ({
  type: CAR_PURPOSE_CHANGED,
});

function getCookieArray(name) {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const parts = cookie.split('=');
    if (parts[0].trim() === name) {
      return parts[1];
    }
  }
  return [];
}

export const populateCarModel = () => (dispatch, getState) => {
  const state = getState();
  const { manufacturingYear, vehicleMake } = state.car;
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  return API.get(
    API_NAMES.GET_CARMODELS,
    apiUris.GET_CARMODELS(manufacturingYear, vehicleMake && vehicleMake.value),
    myInit,
  )
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_CARMODELS}, year: ${manufacturingYear}`);
      dispatch({
        type: POPULATE_CARMODELS,
        vehicleModelsData: response.data.models,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${
          API_NAMES.GET_CARMODELS
        }. year: ${manufacturingYear}, make: ${vehicleMake && vehicleMake.value}`,
        error,
      );
      return Promise.reject(error.message);
    });
};
export const populateCarSeries = () => (dispatch, getState) => {
  const state = getState();
  const { manufacturingYear, vehicleMake, vehicleModel } = state.car;
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  return API.get(
    API_NAMES.GET_CARSERIES,
    apiUris.GET_CARSERIES(
      manufacturingYear,
      vehicleMake && vehicleMake.value,
      vehicleModel && vehicleModel.value,
    ),
    myInit,
  )
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_CARSERIES}, year: ${manufacturingYear}`);
      dispatch({
        type: POPULATE_CARSERIES,
        vehicleSeriesdata: response.data.variants,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${
          API_NAMES.GET_CARSERIES
        }. year: ${manufacturingYear}, make: ${vehicleMake &&
          vehicleMake.value}, model: ${vehicleModel && vehicleModel.value}`,
        error,
      );
      return Promise.reject(error.message);
    });
};
export const populateCarVariants = () => (dispatch, getState) => {
  const state = getState();
  const { manufacturingYear, vehicleMake, vehicleModel, vehicleSeries } = state.car;
  const myInit = {
    response: true,
    queryStringParameters: {
      year: manufacturingYear,
      make: vehicleMake && vehicleMake.value,
      model: vehicleModel && vehicleModel.value,
      variant: vehicleSeries && vehicleSeries.value,
    },
    timeout: 1000 * 10,
  };
  return API.get(API_NAMES.GET_CARVARIANTS, apiUris.GET_CARVARIANTS, myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_CARVARIANTS}, year: ${manufacturingYear}`);
      dispatch({
        type: POPULATE_CARVARIANTS,
        vehicleVariantsdata: response.data.nvics,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${
          API_NAMES.GET_CARVARIANTS
        }. year: ${manufacturingYear}, make: ${vehicleMake &&
          vehicleMake.value}, model: ${vehicleModel &&
          vehicleModel.value}, variant: ${vehicleSeries && vehicleSeries.value}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const getAllVehicleYears = (carCondition) => (dispatch) => {
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  return API.get(API_NAMES.GET_YEARS, apiUris.GET_YEARS, myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_YEARS}`);
      if (carCondition === 'New' || carCondition === 'Demo') {
        const arryDataYears = response.data.years;
        const sortedarray = take(orderBy(arryDataYears, null, ['desc']), 2);
        dispatch({
          type: POPULATE_YEARS,
          yearData: sortedarray,
        });
      }
      if (carCondition === 'Used' || carCondition === '') {
        const arryDataYears = response.data.years;
        const sortedarray = take(orderBy(arryDataYears, null, ['desc']), 20);
        dispatch({
          type: POPULATE_YEARS,
          yearData: sortedarray,
        });
      }
      if (carCondition === 'Unsure') {
        const arryDataYears = response.data.years;
        const sortedarray = take(orderBy(arryDataYears, null, ['desc']), 10);
        dispatch({
          type: POPULATE_YEARS,
          yearData: sortedarray,
        });
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.GET_YEARS}`, error);
      const date = new Date();
      const year = date.getFullYear().toString();
      dispatch({
        type: POPULATE_YEARS,
        yearData: [year],
      });
      return Promise.reject(error.message);
    });
};
export const populateCarMake = () => (dispatch, getState) => {
  const state = getState();
  const { manufacturingYear } = state.car;
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('car', 'isLoadingcarmakes', true, false));
  return API.get(API_NAMES.GET_CARMAKES, apiUris.GET_CARMAKES(manufacturingYear), myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_CARMAKES}, year: ${manufacturingYear}`);
      dispatch(liteFieldChanged('car', 'isLoadingcarmakes', false, false));
      dispatch({
        type: POPULATE_CARMAKES,
        vehicleMakesData: response.data.makes,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.GET_CARMAKES}. year: ${manufacturingYear}`, error);
      dispatch(liteFieldChanged('car', 'isLoadingcarmakes', false, false));
      dispatch({
        type: POPULATE_CARMAKES,
        vehicleMakesData: [],
      });
      Promise.reject(error.message);
    });
};
/* export const populateCarMake = (years) => ({ type: POPULATE_CARMAKE, years });
export const populateCarModel = (vehicleMake) => ({ type: POPULATE_CARMODEL, vehicleMake }); */

export const fetchOpportunity = (cognitoId) => (dispatch) => {
  // const { address } = state.applicants;
  const myInit = {
    response: true,
    queryStringParameters: {
      cognitoId,
    },
    timeout: 1000 * 10,
    headers: {},
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  return API.get(API_NAMES.OPP_EXIST_DASHBOARD, apiUris.DASHBOARDEXISTINGOPP, myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.OPP_EXIST_DASHBOARD}`);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('account', 'opportunityList', response.data, false));
      // dispatch(liteFieldChanged('applicants', 'opportunityId', response.data[0].id, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.OPP_EXIST_DASHBOARD}`, error);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};

export const updateCoginito = (cognitoId) => (dispatch, getState) => {
  const state = getState();
  const { applicantId } = state.account;
  const myInit = {
    response: true,
    body: {
      cognitoId,
    },
    headers: {},
  };
  // const applicantId = '0e309568-77b1-4066-815c-3ec06546f992';
  return API.post(API_NAMES.CREATE_ACCOUNT, apiUris.CREATE_ACCOUNT(applicantId), myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.CREATE_ACCOUNT}, applicantId: ${applicantId}`);
      setCognitoId(cognitoId);
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.CREATE_ACCOUNT}. applicantId: ${applicantId}`, error);
      return Promise.reject(error.message);
    });
};

export const updatePrivacyDoc = () => (dispatch, getState) => {
  dispatch(liteFieldChanged('account', 'updatePrivacyInProgress', true, false));
  const state = getState();
  // const { applicantId } = state.applicants;
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: true,
    body: {
      privacyAcceptance: true,
    },
    headers: {},
  };
  return API.post(
    API_NAMES.ACCEPT_PRIVACY,
    apiUris.UPDATE_PRIVACY(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.ACCEPT_PRIVACY}. opportunityId: ${opportunityId}`);
      dispatch(liteFieldChanged('account', 'updatePrivacyInProgress', false, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.ACCEPT_PRIVACY}. opportunityId: ${opportunityId}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'updatePrivacyInProgress', false, false));
      return Promise.reject(error.message);
    });
};

export const updateDFSDoc = () => (dispatch, getState) => {
  dispatch(liteFieldChanged('account', 'updateDFSDocInProgress', true, false));
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: true,
    body: {
      DFSAccepted: true,
    },
    headers: {},
  };
  return API.post(API_NAMES.ACCEPT_DFS, apiUris.UPDATE_NCCP1(opportunityId, applicantId), myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.ACCEPT_DFS}. opportunityId: ${opportunityId}`);
      dispatch(liteFieldChanged('account', 'updateDFSDocInProgress', false, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.ACCEPT_DFS}. opportunityId: ${opportunityId}`, error);
      dispatch(liteFieldChanged('account', 'updateDFSDocInProgress', false, false));
      return Promise.reject(error.message);
    });
};
export const updateCreditQuote = () => (dispatch, getState) => {
  dispatch(liteFieldChanged('account', 'updateCreeditQuoteInProgress', true, false));
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: true,
    body: {
      creditQuoteAccepted: true,
    },
    headers: {},
  };
  return API.post(API_NAMES.ACCEPT_DFS, apiUris.UPDATE_NCCP1(opportunityId, applicantId), myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.ACCEPT_DFS}. opportunityId: ${opportunityId}`);
      dispatch(liteFieldChanged('account', 'updateCreeditQuoteInProgress', false, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.ACCEPT_DFS}. opportunityId: ${opportunityId}`, error);
      dispatch(liteFieldChanged('account', 'updateCreeditQuoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};
export const createOpportunity = () => (dispatch, getState) => {
  const state = getState();
  const { leadId } = state.applicants;
  const myInit = {
    response: true,
    body: { leadid: leadId, cognitoid: '' },
    headers: {},
  };
  return API.post(API_NAMES.OPP_CREATE, apiUris.OPP_CREATE, myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.OPP_CREATE}. leadId: ${leadId}`);
      dispatch(liteFieldChanged('applicants', 'opportunityId', response.data.oppid, false));
      dispatch(
        liteFieldChanged('applicants', 'applicantId', response.data.applicantids[0].appid, false),
      );
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.OPP_CREATE}. leadId: ${leadId}`, error);
      return Promise.reject(error.message);
    });
};

export const updateQuoteLenderParams = (qualifyingDetails) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId } = state.applicants;
  const myInit = {
    response: true,
    body: qualifyingDetails,
  };
  return API.post(
    API_NAMES.UPDATE_LENDERPARAMS,
    apiUris.UPDATE_LENDER_PARAMS(opportunityId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.UPDATE_LENDERPARAMS}. opportunityId: ${opportunityId}`,
      );
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.UPDATE_LENDERPARAMS}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const selectProductDetails = (selectedProduct) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId } = state.applicants;
  const myInit = {
    response: true,
    body: selectedProduct,
  };
  return API.post(API_NAMES.SELECT_PRODUCT, apiUris.SELECT_PRODUCT(opportunityId), myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.SELECT_PRODUCT}. opportunityId: ${opportunityId}`);
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.SELECT_PRODUCT}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const createSpouseCoApplicants = (
  postLenderSelect = false,
  applicantType = 'Primary Applicant',
) => (dispatch, getState) => {
  const state = getState();
  const { spouseCoApplicantId, opportunityId, applicantId } = state.applicants;

  const fieldSuffix = applicantType !== APPLICANT_TYPE.primary ? `_${applicantType}` : '';

  const title = state.applicants[`title${fieldSuffix}`];
  const firstName = state.applicants[`firstName${fieldSuffix}`];
  const lastName = state.applicants[`lastName${fieldSuffix}`];
  const dateOfBirth = state.applicants[`dateOfBirth${fieldSuffix}`] || null;
  const emailAddress = state.applicants[`email${fieldSuffix}`];
  const mobileNumber = state.applicants[`phoneNumber${fieldSuffix}`];
  const driversLicenseNumber = state.applicants[`driversLicenseNumber${fieldSuffix}`];
  const driversLicencecardNumber = state.applicants[`driversLicenseCardNumber${fieldSuffix}`];
  const driversLicenseType = state.applicants[`driversLicenseType${fieldSuffix}`];
  const driversLicenseState = state.applicants[`driversLicenseState${fieldSuffix}`];
  const internationalLicense = state.applicants[`internationalLicense${fieldSuffix}`];
  const citizenshipStatus = state.applicants[`citizenshipStatus${fieldSuffix}`];
  const visaExpiryDate = state.applicants[`visaExpiryDate${fieldSuffix}`] || null;
  const passportNumber = state.applicants[`passportNumber${fieldSuffix}`];
  const passportCountry = state.applicants[`passportCountry${fieldSuffix}`];
  const passportExpiry = state.applicants[`passportExpiry${fieldSuffix}`] || null;
  const maritalStatus = state.applicants[`maritalStatus${fieldSuffix}`];
  const numberOfDependants = state.applicants[`numberOfDependants${fieldSuffix}`];
  const creditHistory = state.applicants[`creditHistory${fieldSuffix}`];
  const myInit = {
    response: true,
    body: {
      applicantExtId: spouseCoApplicantId || '',
      applicantType: applicantType === APPLICANT_TYPE.coApplicant ? 'Co-Applicant' : 'Spouse',
      creditHistory,
      postLenderSelect,
      title,
      firstName,
      lastName,
      dateOfBirth,
      emailAddress,
      mobileNumber,
      driversLicenseNumber,
      driversLicencecardNumber,
      driversLicenseType,
      driversLicenseState,
      internationalLicense,
      citizenshipStatus,
      visaExpiryDate,
      passportNumber,
      passportCountry,
      passportExpiry,
      maritalStatus,
      numberOfDependants,
    },
    headers: {},
  };
  return API.post(API_NAMES.CO_APPLICANTS, apiUris.GET_CO_APPLICANT(opportunityId), myInit)
    .then((response) => {
      dispatch(liteFieldChanged('applicants', 'spouseCoApplicantId', response.data.id, false));
      log.info(`api call successfull ${API_NAMES.CO_APPLICANTS}. opportunityId: ${opportunityId}`);
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.CO_APPLICANTS}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const applicants = (carSalesFlag = false, pqsubmitVal = false) => (dispatch, getState) => {
  const state = getState();
  const {
    title,
    firstName,
    lastName,
    dateOfBirth,
    email,
    phoneNumber,
    passportFlag,
    passportNumber,
    passportCountry,
    passportExpiry,
    licenceNumber,
    driversLicencecardNumber,
    licenceType,
    dlflag,
    dlState,
    relationship,
    dependants,
    citizenshipStatus,
    isAbnknown,
    visaExpiryDt,
    abnNumber,
    foreseeSelected,
    foreseeFinancialChangesDescription,
    previousFinance,
    termNconditionChk,
    dfsAcceptedDt,
    liabilities,
    isSpousePropOwner,
    residentialStatus,
    isProgressiveQuoteSubmitted,
    assets,
    disclosedCreditRating,
    creditQuoteAcceptedDate,
    creditQuoteAccepted,
    acceptPrivacyPolicy,
    marketingSubscription,
  } = state.applicants;
  const { opportunityId, applicantId } = state.applicants;
  let spouseProperty;
  if (
    showAskPartnerPropDetails(residentialStatus, relationship, assets) &&
    typeof isSpousePropOwner === 'boolean'
  ) {
    spouseProperty = String(isSpousePropOwner);
  } else if (
    showAskPartnerPropDetails(residentialStatus, relationship, assets) &&
    typeof isSpousePropOwner !== 'boolean'
  ) {
    spouseProperty = null;
  } else {
    spouseProperty = null;
  }

  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary Applicant',
        title: title || null,
        firstName,
        middleName: '',
        lastName,
        dateOfBirth: dateOfBirth || null,
        emailAddress: email,
        mobileNumber: phoneNumber,
        // marketingCommunicationOptIn: marketingSubscription || false,
        privacyAcceptance: acceptPrivacyPolicy,
        privacyAcceptanceDate: carSalesFlag ? new Date().toISOString() : null,
        passportFlag: passportFlag || false,
        passportNumber,
        passportCountry,
        passportExpiry: passportExpiry || null,
        driversLicenseNumber: licenceNumber,
        driversLicencecardNumber: driversLicencecardNumber,
        driversLicenseType: licenceType,
        driversLicenseState: dlState,
        disclosedCreditRating,
        internationalLicense: dlflag || false,
        citizenshipStatus,
        maritalStatus: relationship,
        numberOfDependants: dependants,
        visaExpiryDate: visaExpiryDt || null,
        foreseeSelected:
          foreseeSelected === true || foreseeSelected === false ? foreseeSelected : false,
        foreseeFinancialChangesDescription: foreseeFinancialChangesDescription || null,
        // eslint-disable-next-line no-nested-ternary
        previousFinance: previousForLiability(liabilities)
          ? true
          : showAskPreviousLoan(liabilities)
          ? previousFinance
          : null,
        termAndConditionCheck: termNconditionChk || false,
        dfsAcceptedDate:
          termNconditionChk === true && !dfsAcceptedDt
            ? new Date().toISOString()
            : dfsAcceptedDt || null,
        stage: 'Quote',
        'sub Stage': 'Quote',
        isSpousePropertyOwner: spouseProperty,
        isProgressiveQuoteSubmitted: pqsubmitVal || false,
        creditQuoteAccepted:
          Boolean(creditQuoteAccepted) || !!(pqsubmitVal || isProgressiveQuoteSubmitted),
        creditQuoteAcceptedDate:
          creditQuoteAccepted === true && !creditQuoteAcceptedDate
            ? new Date().toISOString()
            : creditQuoteAcceptedDate || null,
      },
    ],
    headers: {},
  };
  return API.post(API_NAMES.APPLICANTS, apiUris.GET_APPLICANT(opportunityId, applicantId), myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.APPLICANTS}. opportunityId: ${opportunityId}`);
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.APPLICANTS}. opportunityId: ${opportunityId}`, error);
      dispatch(handleHTTPError());
      return Promise.reject(error.message);
    });
};

export const clearLeadValues = () => (dispatch) => {
  {
    dispatch({ type: CLEAR_LEAD_VALUES });
    dispatch({ type: RESET_ALL_DATA_APPLICANTS });
    dispatch({ type: RESET_ALL_DATA_ESTIMATION });
  }
};

export const regoNumberSearch = () => (dispatch, getState) => {
  const state = getState();
  const { regoNumber, regoState } = state.car;
  const formattedRegoNumber = regoNumber.replace(/\W+/g, '');
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch({ type: CLEAR_REGOVEHICLEDETAILS });
  dispatch(liteFieldChanged('car', 'searchCar', false));
  dispatch(liteFieldChanged('car', 'regoSearchError', false));
  dispatch(liteFieldChanged('car', 'regoSearchInProgress', true));
  dispatch(liteFieldChanged('account', 'quoteInProgress', true));

  return API.get(
    API_NAMES.REGOVEHICLEDETAILS,
    apiUris.GET_REGODETAILS(formattedRegoNumber, regoState),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.REGOVEHICLEDETAILS}. rego: ${regoNumber}, state: ${regoState}`,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false));
      dispatch({
        type: POPULATE_REGOVEHICLEDETAILS,
        factorydata: response.data,
      });
      dispatch(liteFieldChanged('car', 'searchCar', true));
      dispatch(liteFieldChanged('car', 'regoSearchInProgress', false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.REGOVEHICLEDETAILS}. rego: ${regoNumber}, state: ${regoState}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false));
      dispatch(liteFieldChanged('car', 'regoSearchError', true));
      if (error.response && error.response.status) {
        dispatch(
          validationErrors(
            'We could not find the car you are looking for, make sure you have entered correct details',
          ),
        );
      } else {
        dispatch(
          validationErrors(
            "We couldn't find your registration at this time. Please try again soon.",
          ),
        );
      }
      dispatch(liteFieldChanged('car', 'regoSearchInProgress', false));
      return Promise.reject(error.message);
    });
};

export const getfactoryOptions = () => (dispatch, getState) => {
  const state = getState();
  const { vehicleVariant } = state.car;

  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  return API.get(
    API_NAMES.NVIC,
    apiUris.GET_FACTORYOPTIONS(vehicleVariant && vehicleVariant.value),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.NVIC}. factory options. variant: ${vehicleVariant &&
          vehicleVariant.value}`,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch({
        type: POPULATE_FACTORYOPTIONS,
        factorydata: response.data,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.NVIC}. factory options. variant: ${vehicleVariant &&
          vehicleVariant.value}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};

export const livingDetailsUpdate = (postLenderSelect, applicantType = APPLICANT_TYPE.primary) => (
  dispatch,
  getState,
) => {
  const fieldSuffix = applicantType !== APPLICANT_TYPE.primary ? `_${applicantType}` : '';
  const applicantTypeAddress =
    applicantType === APPLICANT_TYPE.primary
      ? PRIMARY_APPLICANT
      : applicantType === APPLICANT_TYPE.spouse
      ? SPOUSE_APPLICANT
      : CO_APPLICANT;

  const stateVal = getState();
  const nonResident = stateVal.applicants[`nonResident${fieldSuffix}`];
  const unitNumber =
    stateVal.applicants[`unitNumber${fieldSuffix}`] || stateVal.applicants.unitNumber;
  const streetNumber =
    stateVal.applicants[`streetNumber${fieldSuffix}`] || stateVal.applicants.streetNumber;
  const streetName =
    stateVal.applicants[`streetName${fieldSuffix}`] || stateVal.applicants.streetName;
  const streetType =
    stateVal.applicants[`streetType${fieldSuffix}`] || stateVal.applicants.streetType;
  const suburb = stateVal.applicants[`suburb${fieldSuffix}`] || stateVal.applicants.suburb;
  const postcode = stateVal.applicants[`postcode${fieldSuffix}`] || stateVal.applicants.postcode;
  const state = stateVal.applicants[`state${fieldSuffix}`] || stateVal.applicants.state;
  const country = stateVal.applicants[`country${fieldSuffix}`] || stateVal.applicants.country;
  const duration = stateVal.applicants[`duration${fieldSuffix}`];
  const residentialStatus = stateVal.applicants[`residentialStatus${fieldSuffix}`];
  const prevAddresses = stateVal.applicants[`prevAddresses${fieldSuffix}`] || [];
  const landlordName = stateVal.applicants[`landlordName${fieldSuffix}`];
  const landlordPhone = stateVal.applicants[`landlordPhone${fieldSuffix}`];
  const addressId = stateVal.applicants[`addressId${fieldSuffix}`];
  const addressStartDate =
    stateVal.applicants[`addressStartDate${fieldSuffix}`] || stateVal.applicants.addressStartDate;
  const { opportunityId, applicantId, spouseCoApplicantId, coApplicantId } = stateVal.applicants;

  const applicantIdVal =
    applicantType === APPLICANT_TYPE.coApplicant
      ? coApplicantId
      : applicantType === APPLICANT_TYPE.spouse
      ? spouseCoApplicantId
      : applicantId;

  const addressidParam = addressId || '';
  // const addressStartDate = moment().format('YYYY-MM-DD');

  const currentAddressPreProductSelect = [
    {
      addressType: 'Residential Address',
      id: addressidParam,
      internationalAddress: nonResident,
      unitNumber: unitNumber || '',
      streetNumber: streetNumber || '',
      streetName: streetName || '',
      streetType: streetType || '',
      suburb: suburb || '',
      postcode: postcode || '',
      state: state || '',
      country: country || '',
      livedOverTwoYears: duration || false,
      addressStatus: 'Current',
      addressStartDate: addressStartDate || null,
      addressEndDate: (addressStartDate && addressStartDate - 1) || null,
      residentialStatus,
      landlordPhone,
      landlordName,
    },
  ];

  let addresses = [...currentAddressPreProductSelect];
  if (postLenderSelect) {
    addresses = [
      ...currentAddressPreProductSelect,
      ...prevAddresses.map((addressData) => {
        const clonedAddressData = { ...addressData };
        if (clonedAddressData.refId) delete clonedAddressData.refId; // Used for react `keys`
        clonedAddressData.id = clonedAddressData.addressId;
        return clonedAddressData;
      }),
    ];
  }

  const myInit = {
    response: true,
    body: [
      {
        applicantType: applicantTypeAddress,
        postLenderSelect,
        addresses,
      },
    ],
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'addressUpsertInProgress', true, false));
  return API.post(
    API_NAMES.OPP_UPDLIVING_DET,
    apiUris.GET_ADDRESS_UPDATE(opportunityId, applicantIdVal),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_UPDLIVING_DET}. opportunityId: ${opportunityId}`,
      );
      dispatch(liteFieldChanged('account', 'addressUpsertInProgress', false, false));
      response.data.forEach((addrId, idx) => {
        if (idx === 0) {
          dispatch(liteFieldChanged('applicants', `addressId${fieldSuffix}`, addrId, false));
        } else {
          prevAddresses[idx - 1].addressId = addrId;
        }
      });
      if (postLenderSelect) {
        dispatch(
          liteFieldChanged('applicants', `prevAddresses${fieldSuffix}`, prevAddresses, false),
        );
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_UPDLIVING_DET}. opportunityId: ${opportunityId}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'addressUpsertInProgress', false, false));
      dispatch(handleHTTPError());
      return Promise.reject(error.message);
    });
};

export const employmentDetailsUpdateVer2 = (
  postLenderSelect,
  applicantType = APPLICANT_TYPE.primary,
) => (dispatch, getState) => {
  const fieldSuffix = applicantType !== APPLICANT_TYPE.primary ? `_${applicantType}` : '';

  const applicantTypeForEmployment =
    applicantType === APPLICANT_TYPE.primary
      ? PRIMARY_APPLICANT
      : applicantType === APPLICANT_TYPE.spouse
      ? SPOUSE_APPLICANT
      : CO_APPLICANT;

  const stateVal = getState();
  const currEmployments = stateVal.applicants[`currEmployments${fieldSuffix}`];
  const prevEmployments = stateVal.applicants[`prevEmployments${fieldSuffix}`] || [];
  const { opportunityId, applicantId, spouseCoApplicantId, coApplicantId } = stateVal.applicants;

  const applicantIdVal =
    applicantType === APPLICANT_TYPE.coApplicant
      ? coApplicantId
      : applicantType === APPLICANT_TYPE.spouse
      ? spouseCoApplicantId
      : applicantId;

  const MANDATORY_FIELDS = [
    { name: 'employmentStartDate', isNullable: true },
    { name: 'employmentEndDate', isNullable: true },
    { name: 'abnRegistrationDate', isNullable: true },
    { name: 'gstRegistrationdate', isNullable: true },
    { name: 'firstName', isNullable: false },
    { name: 'lastName', isNullable: false },
    { name: 'mobileNumber', isNullable: false },
    { name: 'emailAddress', isNullable: false },
    { name: 'employerAddress', isNullable: false },
    { name: 'unitNumber', isNullable: false },
    { name: 'streetNumber', isNullable: false },
    { name: 'streetName', isNullable: false },
    { name: 'suburb', isNullable: false },
    { name: 'postcode', isNullable: false },
    { name: 'state', isNullable: false },
  ];

  const abnFields = [
    'abnNumber',
    'acn',
    'abnRegistrationDate',
    'abnState',
    'abnStatus',
    'abrEntityType',
    'entityType',
    'entityName',
    'equifaxOrgId',
    'gstRegistrationdate',
    'gstRegistrationstatus',
    'hasABN',
  ];
  const resetEmploymentFields = [
    'employerName',
    'employerABN',
    'industry',
    'occupation',
    'employmentStartDate',
    'firstName',
    'lastName',
    'mobileNumber',
    'emailAddress',
    'employerAddress',
    'unitNumber',
    'streetName',
    'suburb',
    'postcode',
    'state',
    'employmentEndDate',
  ];
  const updateEmploymentFields = (employmentData) => {
    const clonedEmploymentData = {
      ...employmentData,
      hasABN: employmentData.EmploymentType === EMPTYPE_SELF,
    };
    if (clonedEmploymentData.refId) delete clonedEmploymentData.refId; // Used for react `keys`
    if (clonedEmploymentData.errors) delete clonedEmploymentData.errors; // Used for form validation
    if (clonedEmploymentData.EmploymentType !== EMPTYPE_SELF) {
      abnFields.forEach((fieldName) => {
        clonedEmploymentData[fieldName] = null;
      });
    }
    if (
      clonedEmploymentData.EmploymentType === EMPSTATUS_RETIRED ||
      clonedEmploymentData.EmploymentType === EMPSTATUS_UNEMPLOYED ||
      clonedEmploymentData.EmploymentType === EMPTYPE_SELF
    ) {
      resetEmploymentFields.forEach((fieldName) => {
        clonedEmploymentData[fieldName] = null;
      });
    }
    MANDATORY_FIELDS.forEach(({ name, isNullable }) => {
      if (!clonedEmploymentData[name]) {
        if (isNullable) {
          clonedEmploymentData[name] = null;
        } else {
          delete clonedEmploymentData[name];
        }
      }
    });
    return clonedEmploymentData;
  };

  const employment = [
    ...currEmployments.map((employmentData) => updateEmploymentFields(employmentData)),
    ...prevEmployments.map((employmentData) => updateEmploymentFields(employmentData)),
  ];

  const myInit = {
    response: true,
    body: [
      {
        postLenderSelect,
        applicantType: applicantTypeForEmployment,
        employment,
      },
    ],
    headers: {},
    timeout: 1000 * 10,
  };
  return API.post(
    API_NAMES.OPP_EMPLOYMENT_UPD,
    apiUris.GET_EMPLOYMENT(opportunityId, applicantIdVal),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_UPDLIVING_DET}. opportunityId: ${opportunityId}`,
      );

      response.data.empId.forEach((empId, idx) => {
        if (idx === 0) {
          dispatch(liteFieldChanged('applicants', `currentEmpId${fieldSuffix}`, empId, false));
        }
        if (idx < currEmployments.length) {
          currEmployments[idx].currentEmpId = empId;
        } else {
          prevEmployments[idx - currEmployments.length].currentEmpId = empId;
        }
      });
      dispatch(
        liteFieldChanged('applicants', `currEmployments${fieldSuffix}`, currEmployments, false),
      );
      dispatch(
        liteFieldChanged('applicants', `prevEmployments${fieldSuffix}`, prevEmployments, false),
      );
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_UPDLIVING_DET}. opportunityId: ${opportunityId}`,
        error,
      );
      dispatch(handleHTTPError());
      return Promise.reject(error.message);
    });
};

export const getUrlStringdataValues = (data) => ({
  type: REFERRAL_DATA,
  data,
});

export const leadCreation = () => (dispatch, getState) => {
  const state = getState();
  const {
    title,
    firstName,
    lastName,
    email,
    contactPhoneNumber,
    phoneNumber,
    dateOfBirth,
    postcode,
    consentToContact,
    promocode,
  } = state.applicants;
  const {
    carCondition,
    manufacturingYear,
    vehicleMake,
    vehicleModel,
    vehicleBodyType,
    vehicleSeries,
    vehicleVariant,
    purchaseSource,
    purchaseUse,
    regoNumber,
    regoState,
    purchasePrice,
    depositAmount,
    nvic,
    factoryOptions,
    transmission,
    vehicleEngine,
    rcid,
    rrp,
    retail,
    vinNumber,
    trade,
    referralChannelSource,
    referralChannelCampaign,
    referralChannelContent,
    referralChannelCampaignterm,
    referralChannelMedium,
    referralChannel,
    gclid,
    assetType,
    commercialType,
    vehicleModelCode,
    vehicleMakeCode,
  } = state.car;

  const { termVal, residualSwitch } = state.estimation;
  const selectedFactoryOptions =
    factoryOptions && factoryOptions.filter((option) => option.selected);
  const myInit = {
    body: {
      title: title || null,
      firstName: firstName || null,
      lastName: lastName || null,
      emailAddress: email || null,
      mobileNumber: phoneNumber || contactPhoneNumber || null,
      DateOfBirth: dateOfBirth || null,
      hasOptedOutOfEmail: false,
      privacyAcceptance: true,
      postCode: postcode,
      insuranceConsentAcceptance: consentToContact,

      vehicleDetails: {
        vehicle: {
          make: (vehicleMake && vehicleMake.name) || vehicleMake,
          makeCode: vehicleMakeCode ? vehicleMakeCode : vehicleMake && vehicleMake.value,
          model: (vehicleModel && vehicleModel.name) || vehicleModel,
          modelCode: vehicleModelCode ? vehicleModelCode : vehicleModel && vehicleModel.value,
          series: (vehicleSeries && vehicleSeries.value) || vehicleSeries,
          variant: (vehicleVariant && vehicleVariant.name) || vehicleVariant,
          year: manufacturingYear ? Number(manufacturingYear) : null,
          regoNumber: (regoNumber && regoNumber.replace(/\W+/g, '')) || null,
          nvicCode: nvic,
          regoState: regoState || null,
          bodyType: vehicleBodyType || null,
          engineType: vehicleEngine || null,
          transmission: transmission || null,
          rrp: Number(rrp) || 0.0,
          retail: Number(retail) || 0.0,
          vin: vinNumber || null,
          trade: Number(trade) || 0.0,
          assetSubType: (commercialType && commercialType.value) || '',
        },
        vehicleCondition: carCondition,
        vehicleType: assetType,
        purposeOfUse: assetType === COMMERCIAL_EQUIPMENT ? BUSINESS : purchaseUse,
        purchaseSource: carCondition !== 'New' ? purchaseSource : 'Dealer',
        factoryOptions:
          selectedFactoryOptions && selectedFactoryOptions.length ? selectedFactoryOptions : [],
      },
      requestedLoanDetails: {
        loanAmount: purchasePrice - depositAmount,
        purchasePrice,
        depositAmount: depositAmount || 0,
        residualFlag: residualSwitch,
        term: (termVal && termVal * 12) || 60,
      },
      leadReference: {
        referralChannelId: rcid == null
        ? getCookieArray('UTM_Data_Cookie').length > 0
          ? decodeURIComponent(JSON.parse(getCookieArray('UTM_Data_Cookie'))[0].rcid)
          : null
        : decodeURIComponent(rcid),
        source:
          referralChannelSource == null
            ? getCookieArray('UTM_Data_Cookie').length > 0
              ? decodeURIComponent(JSON.parse(getCookieArray('UTM_Data_Cookie'))[0].source)
              : null
            : decodeURIComponent(referralChannelSource),
        medium:
          referralChannelMedium == null
            ? getCookieArray('UTM_Data_Cookie').length > 0
              ? decodeURIComponent(JSON.parse(getCookieArray('UTM_Data_Cookie'))[0].medium)
              : null
            : decodeURIComponent(referralChannelMedium),
        campaign: referralChannelCampaign === null ? !promocode ? getCookieArray('UTM_Data_Cookie').length > 0
        ? decodeURIComponent(JSON.parse(getCookieArray('UTM_Data_Cookie'))[0].campaign)
        : null : promocode : decodeURIComponent(referralChannelCampaign),
        campaignContent: referralChannelContent,
        campaignTerm: referralChannelCampaignterm,
        channel: decodeURIComponent(referralChannel),
        gaId: gclid || null,
      },
      gaid: gclid || null,
    }, // replace this with attributes you need
    headers: {}, // OPTIONAL
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  dispatch(liteFieldChanged('estimation', 'quoteInProgress', true, false));
  return API.post(API_NAMES.LEADCREATION, apiUris.LEADCREATION, myInit)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.LEADCREATION}. ${myInit.body.firstName}, ${myInit.body.lastName}`,
      );
      // Add your code here
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('estimation', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('applicants', 'leadId', response.leadId, false));
      dispatch(liteFieldChanged('applicants', 'isExistingUser', response.isExisting, false));
      dispatch({
        type: REPOPULATE_LOAN_DETAILS,
        quoteResponse: response.latestQuote,
      });
      return Promise.resolve(response);
      // setTabComplete(currentTab);
    })
    .catch((error) => {
      const contactInfo =
        myInit && myInit.body
          ? `${myInit.body.emailAddress} - ${myInit.body.mobileNumber} - ${myInit.body.firstName} - ${myInit.body.lastName}`
          : '';
      log.error(`api call failed ${API_NAMES.LEADCREATION}. contact info: ${contactInfo}`, error);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};
export const onSaveQuoteleadCreation = () => (dispatch, getState) => {
  const state = getState();
  const { title, firstName, lastName, email, phoneNumber } = state.applicants;
  const {
    carCondition,
    manufacturingYear,
    vehicleMake,
    vehicleModel,
    vehicleBodyType,
    vehicleSeries,
    vehicleVariant,
    purchaseSource,
    purchaseUse,
    regoNumber,
    regoState,
    purchasePrice,
    depositAmount,
    nvic,
    factoryOptions,
    transmission,
    vehicleEngine,
    rcid,
    rrp,
    retail,
    vinNumber,
    trade,
    referralChannelSource,
    referralChannelCampaign,
    referralChannelContent,
    referralChannelCampaignterm,
    referralChannelMedium,
    referralChannel,
    gclid,
    assetType,
  } = state.car;
  const { termVal, residualSwitch } = state.estimation;
  const selectedFactoryOptions =
    factoryOptions && factoryOptions.filter((option) => option.selected);
  const myInit = {
    body: {
      title: title || null,
      firstName: firstName || null,
      lastName: lastName || null,
      emailAddress: email || null,
      mobileNumber: phoneNumber || null,
      privacyAcceptance: true,
      vehicleDetails: {
        vehicle: {
          make: (vehicleMake && vehicleMake.name) || vehicleMake,
          makeCode: vehicleMake && vehicleMake.value,
          model: (vehicleModel && vehicleModel.name) || vehicleModel,
          modelCode: vehicleModel && vehicleModel.value,
          series: (vehicleSeries && vehicleSeries.value) || vehicleSeries,
          variant: (vehicleVariant && vehicleVariant.name) || vehicleVariant,
          year: manufacturingYear ? Number(manufacturingYear) : null,
          regoNumber: (regoNumber && regoNumber.replace(/\W+/g, '')) || null,
          bodyType: vehicleBodyType,
          nvicCode: nvic,
          regoState: regoState || null,
          engineType: vehicleEngine || null,
          transmission: transmission || null,
          rrp: Number(rrp) || 0.0,
          retail: Number(retail) || 0.0,
          vin: vinNumber || null,
          trade: Number(trade) || 0.0,
        },
        vehicleCondition: carCondition,
        vehicleType: assetType.toUpperCase(),
        purposeOfUse: assetType.toUpperCase() === COMMERCIAL_EQUIPMENT ? BUSINESS : purchaseUse,
        purchaseSource: carCondition !== 'New' ? purchaseSource : 'Dealer',
        factoryOptions:
          selectedFactoryOptions && selectedFactoryOptions.length ? selectedFactoryOptions : [],
      },
      requestedLoanDetails: {
        loanAmount: purchasePrice - depositAmount,
        purchasePrice,
        depositAmount: depositAmount || 0,
        residualFlag: residualSwitch,
        term: (termVal && termVal * 12) || 60,
      },
      leadReference: {
        referralChannelId: rcid,
        source: referralChannelSource,
        medium: referralChannelMedium,
        campaign: referralChannelCampaign,
        campaignContent: referralChannelContent,
        campaignTerm: referralChannelCampaignterm,
        channel: referralChannel,
        gaId: gclid || null,
      },
    }, // replace this with attributes you need
    headers: {}, // OPTIONAL
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  return API.post(API_NAMES.LEADCREATION, apiUris.LEADCREATION, myInit)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.LEADCREATION}. ${myInit.body.firstName}, ${myInit.body.lastName}`,
      );
      // Add your code here
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('applicants', 'leadId', response.leadId, false));
      return Promise.resolve(response);
      // setTabComplete(currentTab);
    })
    .catch((error) => {
      const contactInfo =
        myInit && myInit.body
          ? `${myInit.body.emailAddress} - ${myInit.body.mobileNumber} - ${myInit.body.firstName} - ${myInit.body.lastName}`
          : '';
      log.error(`api call failed ${API_NAMES.LEADCREATION}. contact info: ${contactInfo}`, error);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};

export const directOpportunityCreation = () => (dispatch, getState) => {
  const state = getState();
  const { cognitoId } = state.account;
  const {
    carCondition,
    manufacturingYear,
    vehicleMake,
    vehicleModel,
    vehicleBodyType,
    vehicleSeries,
    vehicleVariant,
    purchaseSource,
    purchaseUse,
    regoNumber,
    regoState,
    purchasePrice,
    depositAmount,
    nvic,
    factoryOptions,
    transmission,
    vehicleEngine,
    rcid,
    rrp,
    retail,
    vinNumber,
    trade,
    referralChannelSource,
    referralChannelCampaign,
    referralChannelContent,
    referralChannelCampaignterm,
    referralChannelMedium,
    referralChannel,
    gclid,
    assetType,
    commercialType,
  } = state.car;
  const { termVal, residualSwitch } = state.estimation;
  const selectedFactoryOptions =
    factoryOptions && factoryOptions.filter((option) => option.selected);
  const myInit = {
    body: {
      cognitoId: cognitoId || null,
      vehicleDetails: {
        vehicle: {
          make: (vehicleMake && vehicleMake.name) || vehicleMake,
          makeCode: vehicleMake && vehicleMake.value,
          model: (vehicleModel && vehicleModel.name) || vehicleModel,
          modelCode: vehicleModel && vehicleModel.value,
          series: (vehicleSeries && vehicleSeries.value) || vehicleSeries,
          variant: (vehicleVariant && vehicleVariant.name) || vehicleVariant,
          year: manufacturingYear ? Number(manufacturingYear) : null,
          regoNumber: (regoNumber && regoNumber.replace(/\W+/g, '')) || null,
          bodyType: vehicleBodyType,
          nvicCode: nvic,
          regoState: regoState || null,
          engineType: vehicleEngine || null,
          transmission: transmission || null,
          rrp: Number(rrp) || 0.0,
          retail: Number(retail) || 0.0,
          vin: vinNumber || null,
          trade: Number(trade) || 0.0,
          commercialType: (commercialType && commercialType.value) || '',
        },
        vehicleCondition: carCondition,
        vehicleType: assetType?.toUpperCase() || 'CAR',
        purposeOfUse: assetType === COMMERCIAL_EQUIPMENT ? BUSINESS : purchaseUse,
        purchaseSource: carCondition !== 'New' ? purchaseSource : 'Dealer',

        factoryOptions:
          selectedFactoryOptions && selectedFactoryOptions.length ? selectedFactoryOptions : [],
      },
      requestedLoanDetails: {
        loanAmount: purchasePrice - depositAmount,
        purchasePrice,
        depositAmount: depositAmount || 0,
        residualFlag: residualSwitch,
        term: (termVal && termVal * 12) || 60,
      },
      leadReference: {
        referralChannelId: rcid,
        source: referralChannelSource,
        medium: referralChannelMedium,
        campaign: referralChannelCampaign,
        campaignContent: referralChannelContent,
        campaignTerm: referralChannelCampaignterm,
        channel: referralChannel,
        gaId: gclid || null,
      },
    }, // replace this with attributes you need
    headers: {}, // OPTIONAL
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  return API.post(API_NAMES.DIRECT_OPP_CREATE, apiUris.DIRECT_OPP_CREATE, myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.DIRECT_OPP_CREATE}`);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('applicants', 'opportunityId', response.oppid, false));
      dispatch(
        liteFieldChanged('applicants', 'applicantId', response.applicantids[0].appid, false),
      );
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.DIRECT_OPP_CREATE}`, error);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};
export const retrieveAddress = (formattedAddress) => ({
  type: RETRIVE_ADDRRESS_APPLICANTS,
  payload: formattedAddress,
});

export const clearAddress = () => ({ type: CLEAR_ADDRRESS_APPLICANTS });

export const createQuote = () => (dispatch, getState) => {
  dispatch(noErrors());
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));

  const state = getState();
  const {
    purchasePrice,
    carCondition,
    depositAmount,
    purchaseSource,
    purchaseUse,
    manufacturingYear,
    nvic,
    retail,
    rrp,
    trade,
    vehicleMake,
    assetType,
    vehicleModel = null,
    estimatedLoan,
  } = state.car;

  const { termVal, residualSwitch } = state.estimation;
  const currentData = new Date();
  const myInit = {
    body: {
      requestedLoanAmount:
        (Number(purchasePrice) === 0 ? 50000 : Number(purchasePrice)) - Number(depositAmount),
      financeType:
        purchaseUse === 'Personal'
          ? 'ConsumerLoan'
          : purchaseUse === BUSINESS
          ? 'ChattelMortgage'
          : assetType === COMMERCIAL_EQUIPMENT
          ? 'ChattelMortgage'
          : '',
      purchaseSource: carCondition !== 'New' ? purchaseSource : 'dealer',
      condition: carCondition,
      year: manufacturingYear,
      term: (termVal && termVal * 12) || 60,
      purchasePrice: purchasePrice || 50000,
      depositAmount: depositAmount || 0,
      tradeInAmount: 0,
      savings: 0,
      assetType: assetType || 'Car',
      residualAmount: residualSwitch ? null : 0,
      nvic: nvic || '',
      rrp: Number(rrp) || null,
      retail: Number(retail) || null,
      trade: Number(trade) || null,
      quoteType: 'Range',
      residualPercent: residualSwitch ? null : 0,
      make: (vehicleMake && vehicleMake.name) || vehicleMake,
    },
    headers: {}, // OPTIONAL
    timeout: 1000 * 10,
  };

  return API.post(API_NAMES.QUOTE, apiUris.QUOTE, myInit)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.QUOTE}, req.amount ${myInit.body.requestedLoanAmount}`,
      );
      // Add your code here
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('account', 'quote', response, false));
      dispatch({
        type: POPULATE_ESTIMATION,
        quoteResponse: response,
      });
      return Promise.resolve(response);
      // setTabComplete(currentTab);
    })
    .catch((error) => {
      let quoteReq;
      try {
        quoteReq = JSON.stringify(myInit);
      } catch (e) {
        console.warn(e);
      }

      log.error(`api call failed ${API_NAMES.QUOTE}. quoteRequest: ${quoteReq}`, error);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.response);
    });
};

export const carDetails = () => (dispatch, getState) => {
  const state = getState();
  const {
    carCondition,
    regoNumber,
    regoState,
    manufacturingYear,
    vehicleMake,
    vehicleModel,
    vehicleSeries,
    vehicleBodyType,
    vehicleVariant,
    vehicleEngine,
    purchaseSource,
    purchaseUse,
    factoryOptions,
    rrp,
    retail,
    transmission,
    vinNumber,
    trade,
    nvic,
    commercialType,
    assetType,
  } = state.car;
  const { opportunityId } = state.applicants;
  // eslint-disable-next-line max-len
  const selectedFactoryOptions =
    factoryOptions && factoryOptions.filter((option) => option.selected);

  const myInit = {
    response: true,
    body: {
      vehicleCondition: carCondition,
      regoNumber: (regoNumber && regoNumber.replace(/\W+/g, '')) || null,
      regoState,
      year: manufacturingYear,
      make: (vehicleMake && vehicleMake.name) || vehicleMake,
      makeCode: vehicleMake && vehicleMake.value,
      model: (vehicleModel && vehicleModel.name) || vehicleModel,
      modelCode: vehicleModel && vehicleModel.value,
      series: (vehicleSeries && vehicleSeries.value) || vehicleSeries,
      variant: (vehicleVariant && vehicleVariant.name) || vehicleVariant,
      nvic,
      transmission: transmission || null,
      engineType: vehicleEngine || null,
      bodyType: vehicleBodyType || null,
      rrp: (rrp && String(rrp)) || null,
      retail: (rrp && String(retail)) || null,
      vin: vinNumber || null,
      trade: (trade && String(trade)) || null,
      factoryOptions:
        selectedFactoryOptions && selectedFactoryOptions.length ? selectedFactoryOptions : [],
      purchaseSource: carCondition !== 'New' ? purchaseSource : 'Dealer',
      purposeOfUse: assetType === COMMERCIAL_EQUIPMENT ? BUSINESS : purchaseUse,
      commercialEquipmentType: (commercialType && commercialType.value) || '',
      assetSubType: (commercialType && commercialType.value) || '',
    },
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  return API.post(API_NAMES.UPDATE_CAR_dETAILS, apiUris.GET_UPD_CAR_DET(opportunityId), myInit)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.UPDATE_CAR_dETAILS}. opportunityId: ${opportunityId}`,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('account', 'updatedCarData', response.data, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.UPDATE_CAR_dETAILS}. opportunityId: ${opportunityId}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};
export const loanDetails = () => (dispatch, getState) => {
  const state = getState();
  const { purchasePrice, depositAmount, purchaseUse, assetType, isCarPurposeChanged } = state.car;
  const { termVal, residualSwitch } = state.estimation;
  const { opportunityId } = state.applicants;
  const myInit = {
    response: true,
    body: {
      purchasePrice: String(purchasePrice),
      loanAmount: String(purchasePrice - depositAmount),
      tradeinAmount: '0',
      depositAmount: String(depositAmount),
      loanType:
        purchaseUse === 'Personal'
          ? 'Consumer Loan'
          : purchaseUse === BUSINESS
          ? 'Chattel Mortgage'
          : assetType === COMMERCIAL_EQUIPMENT
          ? 'Chattel Mortgage'
          : '',
      term: String(termVal * 12) || String(60),
      includeResidual: residualSwitch,
      isCarPurposeChanged: isCarPurposeChanged,
    },
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  return API.post(API_NAMES.UPDATE_LOAN_dETAILS, apiUris.GET_UPD_LOAN_DET(opportunityId), myInit)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.UPDATE_LOAN_dETAILS}. opportunityId: ${opportunityId}`,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('account', 'updatedEstimatedData', response.data, false));
      dispatch(liteFieldChanged('car', 'isCarPurposeChanged', false, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.UPDATE_LOAN_dETAILS}. opportunityId: ${opportunityId}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};

const oppRetrievalResponseSkeleton = (dispatch, response) => {
  dispatch({
    type: POPULATE_OPP_DETAILS,
    oppDetails: response.data,
  });
  if (response.data.isPQProductSelectionGroup) {
    dispatch(setCompleteTab('compare_loan_options'));
  }
  // Set Car tab details
  dispatch({
    type: POPULATE_CAR_DETAILS,
    carDetails: { ...response.data.carDetails, ...response.data.loanDetails },
  });
  dispatch(setCompleteTab('car'));
  // Set estimation tab details
  dispatch({
    type: POPULATE_LOAN_DETAILS,
    loanDetails: response.data.loanDetails,
  });
  dispatch(setCompleteTab('estimation'));

  dispatch({
    type: POPULATE_POSTLENDERSELECT_DETAILS,
    applicants: response.data.applicants,
    carDetails: response.data.carDetails,
  });

  dispatch(setApplicationStrucutureStepper(APPLICANT_TYPE.primary));

  dispatch({
    type: POPULATE_FINANCES,
    finances: response.data.applicants,
  });
  if (response.data.applicants[0].employment) {
    dispatch(setCompleteTab('applicants'));
  }

  if (
    (response?.data?.applicationStructure.toLowerCase() ===
      APPLICANT_TYPE.coApplicant.toLowerCase() ||
      response?.data?.applicationStructure.toLowerCase() === APPLICANT_TYPE.spouse.toLowerCase()) &&
    response?.data?.applicants.length > 1
  ) {
    if (
      response?.data?.applicants[1]?.employment?.length > 0 &&
      response?.data?.applicants[1]?.employment[0]?.occupation !== ''
    ) {
      dispatch(setCompleteTab('addtional_information'));
    }
  } else {
    if (
      response?.data?.applicants[0]?.employment[0]?.occupation !== '' &&
      response?.data?.applicants[0]?.employment[0]?.industry !== ''
    ) {
      dispatch(setCompleteTab('addtional_information'));
    }
  }

  if (response.data.stage === 'Apply' || response.data.stage === 'Select a Product') {
    dispatch(setCompleteTab('finance_details'));
    dispatch(setCompleteTab('address_details'));
    dispatch(setCompleteTab('coApplicant_address_details'));
    dispatch(setCompleteTab('coApplicant_personalDetails'));
  }
  if (response.data.stage === 'Approved' || response.data.stage === 'Settlement') {
    dispatch(setCompleteTab('finance_details'));
    dispatch(setCompleteTab('address_details'));
    dispatch(setCompleteTab('coApplicant_address_details'));
    dispatch(setCompleteTab('coApplicant_personalDetails'));
    dispatch(setCompleteTab('addtional_information'));
    dispatch(setCompleteTab('upload_document'));
  }
};
export const RetrieveOpportunity = () => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: true,
    queryStringParameters: {
      postLenderSelect: true,
    },
    // }, --temporraily removed hanlded as part of other ticket
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  return API.get(API_NAMES.OPPORTUNITY_RESP, apiUris.GET_OPPORTUNITY_RESP(opportunityId), myInit)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPPORTUNITY_RESP}. opportunityId: ${opportunityId}`,
      );
      if (response?.data?.PQSubmitToLenderDate !== '') {
        dispatch(setCompleteTab('addtional_information'));
        dispatch(setCompleteTab('upload_document'));
      }
      oppRetrievalResponseSkeleton(dispatch, response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPPORTUNITY_RESP}, opportunityId: ${opportunityId}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};

export const handleTopNRequoteAPI = (opportunityId) => {
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  return API.get(API_NAMES.TOPNLATEST, apiUris.GET_TOPNLATEST(opportunityId), myInit);
};

export const handleGetOpportunityDataAPI = (opportunityId) => {
  const myInit = {
    response: true,
    queryStringParameters: {
      postLenderSelect: true,
    },
    timeout: 1000 * 10,
  };
  return API.get(API_NAMES.OPPORTUNITY_RESP, apiUris.GET_OPPORTUNITY_RESP(opportunityId), myInit);
};

export const handleRequoteAPI = (opportunityId) => {
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  return API.get(API_NAMES.REQUOTE, apiUris.GET_REQUOTE(opportunityId), myInit);
};

const wait = (time) => new Promise((callback) => setTimeout(callback, time));

// eslint-disable-next-line max-len
const triggerRequote = (operation, opportunityId, delay, times) =>
  new Promise((resolve, reject) =>
    operation(opportunityId)
      .then((response) => {
        if (!response.data.length && !response.data.quotes && times - 1 > 0) {
          log.info(
            `retrying ${API_NAMES.REQUOTE}. times: ${times}. times == 3 -> 1st retry, times == 2 -> 2nd retry, times == 1 -> 3rd retry. opportunityId: ${opportunityId}`,
          );
          return wait(delay)
            .then(triggerRequote.bind(null, operation, opportunityId, delay, times - 1))
            .then(resolve)
            .catch(reject);
        }
        return resolve(response);
      })
      .catch((reason) => reject(reason)),
  );

const triggerOpportunityData = (operation, opportunityId, delay, times) =>
  new Promise((resolve, reject) =>
    operation(opportunityId)
      .then((response) => {
        if (!response.data.applicants?.length && !response.data.carDetails && times - 1 > 0) {
          log.info(
            `retrying ${API_NAMES.OPPORTUNITY_RESP}. times: ${times}. times == 3 -> 1st retry, times == 2 -> 2nd retry, times == 1 -> 3rd retry. opportunityId: ${opportunityId}`,
          );
          return wait(delay)
            .then(triggerOpportunityData.bind(null, operation, opportunityId, delay, times - 1))
            .then(resolve)
            .catch(reject);
        }
        return resolve(response);
      })
      .catch((reason) => reject(reason)),
  );

const triggertopNRequote = (operation, opportunityId, delay, times) =>
  new Promise((resolve, reject) =>
    operation(opportunityId)
      .then((response) => {
        if (!response.data.length && !response.data.quotes && times - 1 > 0) {
          log.info(
            `retrying ${API_NAMES.TOPNLATEST}. times: ${times}. times == 3 -> 1st retry, times == 2 -> 2nd retry, times == 1 -> 3rd retry. opportunityId: ${opportunityId}`,
          );
          return wait(delay)
            .then(triggertopNRequote.bind(null, operation, opportunityId, delay, times - 1))
            .then(resolve)
            .catch(reject);
        }
        return resolve(response);
      })
      .catch((reason) => reject(reason)),
  );

// get selectgroup flag

export const handleSelectProductAPI = (opportunityId) => {
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 20,
  };
  return API.get(
    API_NAMES.GET_ISSELECTPRODUCTGRP,
    apiUris.IS_PRODSELECT_GRP(opportunityId),
    myInit,
  );
};

const triggerSelectProductAPI = (operation, opportunityId, delay, times) =>
  new Promise((resolve, reject) =>
    operation(opportunityId)
      .then((response) => {
        if (!response.data && times - 1 > 0) {
          log.info(
            `retrying ${API_NAMES.GET_ISSELECTPRODUCTGRP}. times: ${times}. times == 3 -> 1st retry, times == 2 -> 2nd retry, times == 1 -> 3rd retry. opportunityId: ${opportunityId}`,
          );
          return wait(delay)
            .then(triggerSelectProductAPI.bind(null, operation, opportunityId, delay, times - 1))
            .then(resolve)
            .catch(reject);
        }
        return resolve(response);
      })
      .catch((reason) => reject(reason)),
  );

export const getSelectProductGroup = () => (dispatch, getState) => {
  const state = getState();
  const { opportunityId } = state.applicants;

  return triggerSelectProductAPI(handleSelectProductAPI, opportunityId, 20000, 2)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.GET_ISSELECTPRODUCTGRP}. opportunityId: ${opportunityId}`,
      );
      dispatch(liteFieldChanged('applicants', 'IsPQProductSelectionGroup', response.data, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.GET_ISSELECTPRODUCTGRP}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject((error && error.message) || error);
    });
};

export const reQuote = () => async (dispatch, getState) => {
  const state = getState();
  const { opportunityId } = state.applicants;
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));

  return triggerRequote(handleRequoteAPI, opportunityId, 2000, 3)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.REQUOTE}. opportunityId: ${opportunityId}`);
      if (response.data.quotes?.length > 0) {
        dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
        dispatch({
          type: REPOPULATE_LOAN_DETAILS,
          quoteResponse: response.data,
        });

        return Promise.resolve(response);
      }
      log.error(
        `api call failed  ${API_NAMES.REQUOTE}.  Exhausted retries. opportunityId: ${opportunityId}`,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(new Error('Exhausted retries'));
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.REQUOTE}. opportunityId: ${opportunityId}`, error);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject((error && error.message) || error);
    });
};

export const topNLatestReQuote = () => async (dispatch, getState) => {
  const state = getState();
  const { opportunityId } = state.applicants;

  dispatch(liteFieldChanged('estimation', 'quoteInProgress', true, false));
  return triggertopNRequote(handleTopNRequoteAPI, opportunityId, 5000, 4)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.TOPNLATEST}. opportunityId: ${opportunityId}`);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      if (response.data.quotes && response.data.quotes.length > 0) {
        dispatch({
          type: POPULATE_QUOTE_DETAILS,
          requoteResponse: response.data,
        });

        return Promise.resolve(response);
      }
      log.error(
        `api call failed  ${API_NAMES.TOPNLATEST}.  Exhausted retries. opportunityId: ${opportunityId}`,
      );
      return Promise.reject(new Error('Exhausted retries'));
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.TOPNLATEST}. opportunityId: ${opportunityId}`, error);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject((error && error.message) || error);
    });
};

export const getOpportunityDataForCarsales = () => async (dispatch, getState) => {
  const state = getState();
  const { opportunityId } = state.applicants;

  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));

  return triggerOpportunityData(handleGetOpportunityDataAPI, opportunityId, 5000, 4)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPPORTUNITY_RESP}. opportunityId: ${opportunityId}`,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));

      if (response.data?.applicants && response.data.applicants?.length > 0) {
        oppRetrievalResponseSkeleton(dispatch, response);

        return Promise.resolve(response);
      }
      log.error(
        `api call failed  ${API_NAMES.OPPORTUNITY_RESP}.  Exhausted retries. opportunityId: ${opportunityId}`,
      );
      return Promise.reject(new Error('Exhausted retries'));
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPPORTUNITY_RESP}. opportunityId: ${opportunityId}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject((error && error.message) || error);
    });
};

export const validateIfLeadExist = () => (dispatch, getState) => {
  const state = getState();
  const { leadId } = state.applicants;
  const myInit = {
    response: true,
    queryStringParameters: {
      leadId,
    },
    timeout: 1000 * 10,
    headers: {},
  };
  return API.get(API_NAMES.GET_ACTIVE_LEAD_RECORD, apiUris.GET_ACTIVE_LEADS, myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_ACTIVE_LEAD_RECORD}`);
      dispatch(liteFieldChanged('car', 'ifLeadRecExist', response.data, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.GET_ACTIVE_LEAD_RECORD}`, error);
      return Promise.reject(error.message);
    });
};

export const validateEnquiryDetails = (token) => (dispatch, getState) => {
  const state = getState();
  const {
    contactId,
    rcid,
    referralChannelSource,
    referralChannelMedium,
    referralChannelCampaign,
    referralChannel,
    assetType,
  } = state.car;
  const myInit = {
    response: true,
    body: {
      token: token,
      assetType: assetType ? assetMappingSF(assetType) : '',
      rcid: rcid ? rcid : '',
      source: referralChannelSource ? referralChannelSource : '',
      medium: referralChannelMedium ? referralChannelMedium : '',
      campaign: referralChannelCampaign ? referralChannelCampaign : '',
      channel: referralChannel ? referralChannel : '',
    },
    headers: {},
  };
  return API.post(API_NAMES.VALIDATE_ENQUIRY, apiUris.VALIDATE_ENQUIRY(contactId), myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.VALIDATE_ENQUIRY}.`);
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.VALIDATE_ENQUIRY}.`, error);
      return Promise.reject(error.message);
    });
};
